// @ts-nocheck

import React from 'react';

class Header extends React.Component {
  state = {};

  renderSteps = (step) => {
    return [1, 2, 3, 4].map((d, i) => {
      return (
        <span key={i} className={step === i ? 'bf-step active' : 'bf-step'}>
          &nbsp;
        </span>
      );
    });
  };

  render() {
    const { step, history } = this.props;
    return (
      <div className="bf-header">
        <div className="gutter-in-16 clearfix">
          <div className="bf-header__left">
            <span
              onClick={() => {
                history.goBack();
              }}
              className="icon back ml-min-4 mt-min-6"></span>
          </div>
          <div className="bf-header__right">
            <div className="r-min-6">{this.renderSteps(step)}</div>
            <img
              className="bf-heade__img"
              src={require('../../assets/images/png/logo.png')}
              alt="Belfood"></img>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
