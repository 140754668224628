import React from 'react';

const Loading = ({ show, text, children, inline }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="absolute-full bg-black-opacity">
      <div className="absolute-center text-center ">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
  return (
    <div className={inline ? null : 'loading__block'}>
      {children}
      <div className="loading__wrapper">
        {text && <h2 className="text-center">{text}</h2>}
      </div>
    </div>
  );
};

export default React.memo(Loading);
