import React, { SVGAttributes } from 'react';
const collections = {
  m: ({ size, color, ...rest }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={size}
        height={size}
        fill={color}
        {...rest}>
        <path
          fillRule="evenodd"
          d="M14.53 10.53a7 7 0 0 1-9.058-9.058A7.003 7.003 0 0 0 8 15a7.002 7.002 0 0 0 6.53-4.47z"
        />
      </svg>
    );
  },
  moon: ({ size, color, ...rest }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={size}
        height={size}
        fill={color}
        {...rest}>
        <path
          fillRule="evenodd"
          d="M14.53 10.53a7 7 0 0 1-9.058-9.058A7.003 7.003 0 0 0 8 15a7.002 7.002 0 0 0 6.53-4.47z"
        />
      </svg>
    );
  },
};
export const svgNames = ['m', 'moon'];
interface P extends SVGAttributes<SVGElement> {
  name?: 'm | moon';
  size?: number | string;
  color?: string;
}
const SvgIcon: React.FC<P> = ({ name, ...rest }) => {
  const Comp = collections[name];
  return <Comp {...rest}></Comp>;
};

SvgIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
};

export default React.memo(SvgIcon);
