// @ts-nocheck

const APP = {
  API_URL: window.APIURL,
  PRODUCT_URL: window.PRODUCTURL,
  // PRODUCT_URL: 'http://127.0.0.1:8000/api/storage/products/',
  // API_URL: 'http://127.0.0.1:8000/api/',
  CAPTCHA_KEY: '6LexIJIUAAAAAAU3xRwgXnYGJOKVSPFgN6iz6F64',
  INITIALAUTH: '/sample-landing',
};

export default APP;
