// @ts-nocheck

import React from 'react';
import { connect } from 'react-redux';

import { LoadingBar, Loading } from 'components';

class GlobalLoading extends React.Component {

  static defaultProps = {
    loading: { visible: true }
  };

  loadingTimeOut = 200;

  state = {
    loadingClass: '',
    isRender: false
  };

  componentDidMount() {
    if (this.props.loading && this.props.loading.visible && !this.state.isRender) {
      this.handleVisible(this.props.loading.visible);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps && prevProps.loading !== this.props.loading) {
      this.handleVisible(this.props.loading.visible);
    }
  }

  handleVisible(isVisible) {
    if (isVisible) return this.setShow();
    return this.setHide();
  }

  setShow() {
    this.setState({
      loadingClass: 'is-open',
      isRender: true
    });
    setTimeout(() => {
      this.setState({
        loadingClass: this.state.loadingClass + ' is-active'
      });
    }, this.loadingTimeOut);
  }

  setHide() {
    this.setState({
      loadingClass: 'is-open'
    });
    setTimeout(() => {
      this.setState({
        loadingClass: '',
        isRender: false
      });
    }, this.loadingTimeOut);
  }

  render() {
    // console.log("this.props", this.props);
    const { loading } = this.props;
    const { options } = loading;
    const { type, text } = options;

    const { loadingClass, isRender } = this.state;
    const cName = 'loading-container ' + loadingClass;

    if (!isRender) {
      return null;
    }

    return (
      <div className={cName}>
        {type === 'bar' ? (
          <LoadingBar />
        ) : (
            <Loading text={text}>
              <LoadingBar />
            </Loading>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(GlobalLoading);
