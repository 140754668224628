export const TYPE = {
  SHOW_POPUP: 'GLOBAL/SHOW_POPUP',
  HIDE_POPUP: 'GLOBAL/HIDE_POPUP',
};

const INITIALSTATE = {
  visible: false,
  options: {
    type: '',
    title: '',
    body: '',
    closeText: null,
    refreshText: null,
    closeAction: null,
    refreshAction: null,
    children: null,
  },
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case TYPE.SHOW_POPUP: {
      return {
        ...state,
        visible: true,
        options: action.payload,
      };
    }
    case TYPE.HIDE_POPUP: {
      return {
        ...state,
        visible: false,
      };
    }
    default:
      return state;
  }
}
