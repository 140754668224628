import { store } from '../../store';
import { TYPE } from './index';

const show = (options) => {
  store.dispatch({
    type: TYPE.SHOW_MODAL,
    payload: options,
  });
};

const hide = () => {
  store.dispatch({
    type: TYPE.HIDE_MODAL,
  });
};

const modalAction = {
  show,
  hide,
};

export default modalAction;
