// @ts-nocheck
const mokitaRegex = {
  sms: /Kode(\s)OTP(\s)(Wahana(\s)Honda|So(B|b)i|(D|d)aya(\s)(A|a)uto|Motorku(\s)(Express|(X|x))|Brompit)(\s)Kamu(\s)adalah(\s)([\d]{6})/,
  smsNumber: /[\d]{6}/,
  vehicle: /^(([a-zA-Z]){1,2}(\s)?)((\d){1,4})(\s)?(([a-zA-Z]{1,3}))$/,
  alphaNumericAndSpace: /[^a-zA-Z0-9\s]+/g,
  onlyNumber: /^\d+$/,
};

const required = (fieldName, customMessage?) => (value) =>
  value ? undefined : customMessage || `Masukan ${fieldName}`;

const maxLength = (max, customMessage?) => (value) =>
  value && value.length > max
    ? customMessage || `Harus ${max} karakter`
    : undefined;

const minLength = (min, customMessage?) => (value) =>
  value && value.length < min
    ? customMessage || `Harus ${min} karakter`
    : undefined;

const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Alamat email tidak valid'
    : undefined;

const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9]/i.test(value)
    ? 'Hanya boleh angka dan huruf, tanpa spasi'
    : undefined;

const numberic = (value) =>
  value && /[^0-9]/.test(value) ? 'Hanya boleh angka' : undefined;

const phoneNumber = (value) =>
  value && !/^(08)[0-9]{9,13}$/.test(value) ? 'Hanya boleh angka' : undefined;

const vehicleNumber = (value) => {
  return value && !mokitaRegex.vehicle.test(value)
    ? 'Nomor Polisi tidak valid'
    : undefined;
};

const idNumber = (value) => {
  return value && !/^[0-9]{16}$/.test(value)
    ? 'Nomor KTP tidak valid'
    : undefined;
};

const engineNumber = (value) => {
  return value.length < 7 ? 'Engine number tidak valid' : undefined;
};

const mobileNumber = (value) =>
  value && phoneNumber(value) ? 'Nomor handphone tidak valid' : undefined;

const validateOTP = (value, isVoucher) => {
  // value && (numberic(value) || value.length !== 6) ? 'Must be 6 digits' : undefined;
  if (value && (numberic(value) || value.length !== 6)) {
    return 'Harus 6 digit';
  } else {
    return undefined;
  }
};

const validateCode = (value) => {
  if (value && (numberic(value) || value.length !== 5)) {
    return 'Harus 5 digit';
  } else {
    return undefined;
  }
};

export default {
  engineNumber,
  required,
  maxLength,
  minLength,
  email,
  alphaNumeric,
  mobileNumber,
  validateOTP,
  numberic,
  validateCode,
  idNumber,
  vehicleNumber,
};
