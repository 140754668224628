import moment from 'moment';
import { mokitaRegex } from './const';
import _ from 'lodash';
import AESENC from './encAes';

const formatDate = (date: any, format: string = 'D MMMM YYYY') => {
  moment.updateLocale('en', {
    months: [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ],
    monthsShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'Mei',
      'Jun',
      'Jul',
      'Ags',
      'Sep',
      'Okt',
      'Nov',
      'Des',
    ],
    weekdays: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
  });
  return moment(date).format(format);
};

const limitArray = (dataArr: any, limit) => {
  if (dataArr && dataArr.length) {
    return dataArr.slice(0, limit);
  }
  return dataArr;
};

const getIdFromArrayObject = (dataArr: any) => {
  return dataArr.map((d, index) => {
    return d.id;
  });
};

const getDatabyIndex = (data: any, i: number) => {
  return data[i];
};

const getGoogleAddress = (data: any) => {
  const temp = {
    country: '', // negara
    province: '', // provinsi, state
    city: '', // city, kabupaten
    district: '', // kecamatan
    subdistrict: '', // kelurahan, desa
    address: '', // jalan
    postalcode: '', // kode post,
    formatted_address: '',
    address_detail: '',
    location: {},
    latitude: null,
    longitude: null,
    place_id: '',
  };

  if (
    data &&
    data.address_components &&
    Array.isArray(data.address_components)
  ) {
    data.address_components.forEach((d: any) => {
      const types = d.types[0];
      if (types === 'country') {
        temp.country = d.long_name;
      }
      if (types === 'administrative_area_level_1') {
        temp.province = d.long_name;
      }
      if (types === 'administrative_area_level_2') {
        temp.city = d.long_name;
      }
      if (types === 'administrative_area_level_3') {
        temp.district = d.long_name;
      }
      if (types === 'administrative_area_level_4') {
        temp.subdistrict = d.long_name;
      }
      if (types === 'route') {
        temp.address = d.long_name;
      }
      if (types === 'postal_code') {
        temp.postalcode = d.long_name;
      }
    });
  }

  if (data.formatted_address) {
    temp.formatted_address = data.formatted_address;
    temp.address_detail = data.formatted_address;
    temp.address = data.formatted_address;
  }

  if (data.place_id) {
    temp.place_id = data.place_id;
  }

  if (data.geometry && data.geometry.location) {
    temp.location = {
      latitude: data.geometry.location.lat(),
      longitude: data.geometry.location.lng(),
    };
    temp.latitude = data.geometry.location.lat();
    temp.longitude = data.geometry.location.lng();
  }

  return temp;
};

const removeArrayObjectById = (dataArr, arrId) => {
  const temp = [];

  for (let i = 0; i < dataArr.length; i++) {
    const d = dataArr[i];
    if (!arrId.includes(d.id)) {
      temp.push(d);
    }
  }
  return temp;
};

const removeArrayObjectByKeyValue = (arr, key, value) => {
  return arr.splice(
    arr.findIndex(function (i) {
      return i[key] === value;
    }),
    1
  );
};

const filterLastVisit = (dataArr: any) => {
  return limitArray(dataArr, 2);
};

const filterAhassDealer = (dataArr: any, arrId) => {
  const temp = removeArrayObjectById(dataArr, arrId);
  for (let i in temp) {
    temp[i].motor = 1;
  }
  return temp;
};

const filterAhassList = (dataArr: any, arrId) => {
  return removeArrayObjectById(dataArr, arrId);
};

const printDate = (date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Ags',
    'Sep',
    'Okt',
    'Nov',
    'Des',
  ];

  if (date) {
    date = date.split('-');

    const yaer = date[0];
    const month = date[1];
    const day = date[2];
    return parseInt(day) + ' ' + months[parseInt(month) - 1] + ' ' + yaer;
  }
  return '-';
};

const getDay = () => {
  return new Date().getDay();
};

const printNewsDate = (date) => {
  const months = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];
  const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

  // date = createDate(date);
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth();
  const dt = date.getDate();
  const day = date.getDay();

  if (year) {
    return `${days[parseInt(day)]}, ${dt} ${months[parseInt(month)]} ${year}`;
  }
  return null;
};

const dateValue = (date) => {
  if (!date) {
    return false;
  }
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  return year + '-' + addZero(month + 1) + '-' + addZero(day);
};

const newFormatCurr = (p, n = 0, x = 3, s = '.', c = ',') => {
  if (!p) {
    return '';
  }
  let str = removeDot(removeSpace(p));
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = Number(str).toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num).replace(
    new RegExp(re, 'g'),
    '$&' + (s || ',')
  );
};

const formatCurrency = (num: string | number) => {
  if (!num) {
    return '-';
  }

  let str = removeDot(removeSpace(num)).toString().split('.');
  if (str[0].length >= 5) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1.');
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
};

const displayPrice = (value) => {
  return `Rp ${value === 0 ? '-' : newFormatCurr(value)}`;
};

const displayMinMaxPrice = (min: string | number, max: string | number) => {
  if (min == 0 && max == 0) {
    return 'Rp -';
  }

  return `${displayPrice(min)} - ${displayPrice(max)}`;
};

const checkValue = (data: any) => {
  return typeof data === 'undefined' ? false : data;
};

const compareProfile = (localData, newData) => {
  if (checkValue(newData.profile)) {
    return newData.profile;
  } else {
    if (localData && !isEmpty(localData)) {
      return localData;
    }
  }
  return {
    name: 'Anonymous',
    point: 0,
  };
};

const addZero = (number) => {
  return number < 10 ? '0' + number : number;
};

const isUrl = (url: string) => {
  if (url) {
    const matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
    return matcher.test(url);
  }
  return false;
};

const trimText = (str: string, limit: number = 100) => {
  const trimmedString =
    str.length > limit ? str.substring(0, limit - 3) + '...' : str;
  return trimmedString;
};

const isEmpty = (param) => {
  try {
    let type = param.constructor.toString();
    if (type.indexOf('Object') > 0) {
      return Object.keys(param).length === 0;
    }
    if (type.indexOf('Array') > 0 || type.indexOf('String') > 0) {
      return param.length ? false : true;
    }
  } catch (e) {}

  return false;
};

const getLastArray = (arr) => {
  if (arr && arr.length) {
    return arr[arr.length - 1];
  }
};

const getKeyByRandom = () => {
  return 'key' + Math.floor(Math.random() * Math.floor(100000));
};

const getKeybyDate = () => {
  const date = new Date();
  return 'key' + date.getTime();
};

const milesToKm = (miles) => {
  const distance = miles * 1.60934;
  if (distance >= 1) {
    return distance.toString().slice(0, 4) + ' KM';
  } else {
    return (distance * 1000).toString().slice(0, 3) + ' Meter';
  }
};

const sizeToScreenRatio = (
  originalWidth: number,
  OriginalHeight: number,
  newWidth: number
): {
  height: number;
  newWidth: number;
} => {
  const newHeight = (OriginalHeight * newWidth) / originalWidth;
  return {
    height: newHeight,
    newWidth: newWidth,
  };
};
const isLoading = (data) => {
  if (data) {
    return data.reqLoading;
  }
  return false;
};

const getPercentByLength = (full, now) => {
  if (now < full) {
    return (now / full) * 100;
  }
  return 100;
};
const isDateAvaliable = (newDate: any) => {
  if (!newDate) {
    return null;
  }
  let dateNow = new Date().getTime();
  newDate = new Date(newDate).getTime();
  return dateNow <= newDate;
};

const validateMaxPoint = (point) => {
  if (point) {
    return point;
  }
  return 700;
};

const getObjectByValueName = (options) => {
  if (!options) {
    return null;
  }
  for (let i in options.data) {
    if (options.data[i][options.keyName] == options.value) {
      return options.data[i];
    }
  }
  return null;
};

const filterDataById = (data, id) => {
  if (data && id) {
    return data.filter((d) => {
      return d.city_id == id;
    });
  }
  return null;
};

const addIdCity = (data) => {
  if (data) {
    for (let index = 0; index < data.length; index++) {
      data[index].id = data[index].city_id;
    }
    return data;
  }
  return [];
};

const cleanSpecialChar = (val) => {
  if (!val) {
    return '';
  }
  return String(val).replace(/[^a-zA-Z ]/g, '');
};

const addVehicleSpace = (val) => {
  var output = [];
  val = val.split(' ');
  val.forEach(function (item) {
    output.push(item.replace(/\'/g, '').split(/(\d+)/));
  });
  return output[0][0] + ' ' + output[0][1] + ' ' + output[0][2];
};

const haveSpace = (val) => {
  if (!val) {
    return false;
  }
  const find = val.match(/\s/g);
  if (find) {
    return find.length;
  }
  return false;
};

const removeSpace = (val: any) => {
  return String(val).replace(/\s/g, '');
};
const removeDot = (val: any) => {
  return String(val).replace(/\./g, '');
};

const printMileage = (val) => {
  let count = 0;
  return String(val)
    .split('')
    .reduceRight((a, c) => {
      if (count === 3) {
        a.push('.');
        count = 1;
      } else {
        count++;
      }
      a.push(c);
      return a;
    }, [])
    .reverse()
    .join('');
};

// const formatVehicle = (val) => {
//   if (mokitaRegex.vehicle.test(val) && !haveSpace(val)) {
//     return addVehicleSpace(val);
//   }
//   return val;
// };

const getYearLists = (yearStart) => {
  const years = [];
  const currentYears = new Date().getFullYear();
  for (let y = currentYears; y >= parseInt(yearStart); y--) {
    years.push({
      label: y,
      name: String(y),
      id: y,
    });
  }
  return years;
};

const getIconNameByLevel = (level) => {
  if (level) {
    return 'icon' + level;
  }
  return 'iconRacer';
};

const objectToArray = (data) => {
  if (!data) {
    return [];
  }
  if (!data.length) {
    return [];
  } else {
    let d = [];
    for (let i in data) {
      d.push(data[i].date);
    }
    return d;
  }
};

const toRad = (rad) => {
  return (rad * Math.PI) / 180;
};

const toDeg = (deg) => {
  return deg * (180 / Math.PI);
};

const middlePoint = (lat1, lng1, lat2, lng2) => {
  const dLng = toRad(lng2 - lng1);

  const _lat1 = toRad(lat1);
  const _lat2 = toRad(lat2);
  const _lng1 = toRad(lng1);

  const bX = Math.cos(_lat2) * Math.cos(dLng);
  const bY = Math.cos(_lat2) * Math.sin(dLng);
  const lat3 = Math.atan2(
    Math.sin(_lat1) + Math.sin(_lat2),
    Math.sqrt((Math.cos(_lat1) + bX) * (Math.cos(_lat1) + bX) + bY * bY)
  );
  const lng3 = _lng1 + Math.atan2(bY, Math.cos(_lat1) + bX);

  return {
    latitude: toDeg(lat3),
    longitude: toDeg(lng3),
  };
};

const getDistance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371;
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

const setDistance = (data, currentLat, currentLon) => {
  if (data) {
    for (let i in data) {
      data[i].distance = getDistanceFromLatLonInKm(
        parseFloat(currentLat),
        parseFloat(currentLon),
        parseFloat(data[i].latitude),
        parseFloat(data[i].longitude)
      );
      data[i].distanceOrigin = data[i].distance;
    }
    data = data.sort((a, b) => {
      return a.distance - b.distance;
    });
  }

  return data;
};

const setMarkerDistance = (data, currentLat, currentLon) => {
  if (data) {
    for (let i in data) {
      data[i].marker_distance = getDistanceFromLatLonInKm(
        parseFloat(currentLat),
        parseFloat(currentLon),
        parseFloat(data[i].latitude),
        parseFloat(data[i].longitude)
      );
    }
    return data.sort((a, b) => {
      return a.marker_distance - b.marker_distance;
    });
  }
  return data;
};

const createFakeDistance = (dataArr) => {
  for (let i in dataArr) {
    dataArr[i].distance = 0;
  }
  return dataArr;
};

const setRoundThenFixed = (n: number) => {
  return (Math.round(n * 100) / 100).toFixed(2);
};

const printDirectionInKm = (n: number) => {
  if (n) {
    return setRoundThenFixed(n) + 'Km';
  }
};

const setMeterToKm = (n: number, unit: string) => {
  if (n) {
    if (unit) {
      return (Math.round(n * 100) / 1000).toFixed(1) + unit;
    }
    return (Math.round(n * 100) / 1000).toFixed(1);
  }
  return null;
};

const getUserNameByEmail = (email: string) => {
  if (email.indexOf('@') > -1) {
    const userName = email.split('@');
    return userName[0];
  }
  return email;
};

/*
const openCall = (phoneNumber: number | string) => {
    if (!phoneNumber) {
        showModal({
            title: "Oops! Terjadi kesalahan.",
            text: "Silahkan coba lagi",
            iconName: "iconFail",
            buttonText: "Kembali",
            footerAction: hideModal,
        });

    } else {
        return Linking.openURL(`tel:${phoneNumber}`);
    }

}
*/

const debugGroup = (groupName: string, ...args) => {
  console.group(`${groupName} - START`);
  args.map((item) => console.log({ [item]: item }));
  console.groupEnd();
};

const fibonacciNumber = (n = 12) => {
  if (n === 1) {
    return [0, 1];
  } else {
    var s = fibonacciNumber(n - 1);
    s.push(s[s.length - 1] + s[s.length - 2]);
    return s;
  }
};
const motorListData = (data) => {
  const addMotor = {
    id: 'addMotor',
    type: 'add',
  };
  if (data && data.length) {
    return [...data, addMotor];
  }
  return [addMotor];
};

const addSerchKey = (data: any) => {
  if (data && data.length) {
    for (let index = 0; index < data.length; index++) {
      data[index].searchKey = data[index].name.toLowerCase();
    }
    return data;
  }
  return [];
};

const randomString = (index: number): string => {
  const d = [
    'Dqqevq?qxq;qxq;q?qqe;qxqq;xlqq?oqq?xp xqqB;qqx?qq;yx',
    'Aqqzlqqz;qeqpqzq;qzqqqqh Iqzq;qqnqqz;dqqoqq;znqqesqiqa',
    'FqxE :; Ixnx;dax;qzqzzqzzqxazxzm, zGzxq?azxmzqzxzqazxl, zBzxqz?qEzx :; Ozxqzu;zxqjxaqx, Kxqiz;xqbzzqxo;zzx, Azz;nqwa;zr',
  ];
  return d[index].replace(/x|q|z|\;|\?/g, '');
};

const paramsToObject = (params) => {
  if (params && params.indexOf('=') >= 0) {
    return JSON.parse(
      `{"${decodeURI(params)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/[=]/g, '":"')}"}`
    );
  }
  return null;
};

const getParamsFromLinking = (data) => {
  if (data && data.indexOf('://') >= 0) {
    return paramsToObject(data.split('?')[1]);
  }
  return null;
};

const typeOf = (data) => {
  if (typeof data === 'undefined') {
    return 'undefined';
  }
  if (data === null) {
    return null;
  }
  if (data.constructor === [].constructor) {
    return 'array';
  }
  if (data.constructor === {}.constructor) {
    return 'object';
  }
  return typeof data;
};

const isCanParseJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const parseToJson = (str) => {
  let data = str;
  try {
    data = JSON.parse(str);
  } catch (e) {
    return data;
  }
  return data;
};

const isCanStringifyJson = (str) => {
  try {
    JSON.stringify(str);
  } catch (e) {
    return false;
  }
  return true;
};

const objectToParams = (obj) => {
  if (!obj) {
    return '';
  }
  let param = '';
  if (typeOf(obj) === 'undefined') {
    return param;
  } else {
    if (typeOf(obj) !== 'object') {
      throw new Error('data must be object');
    }
    for (const key in obj) {
      param += param === '' ? '?' : '&';
      param += `${key}=${encodeURIComponent(obj[key])}`;
    }
    // console.log('paramHERRRR');
    // console.log(param);
    return param;
  }
  return param;

  // var str = Object.keys(obj)
  //   .map(function (key, index) {
  //     console.log('index====>ss');
  //     console.log(index);
  //     if (index === 0) {
  //       return '?' + key + '=' + obj[key];
  //     } else {
  //       return key + '=' + obj[key];
  //     }
  //   })
  //   .join('&');
  // return str;

  // console.log('str====>');
  // console.log(str);

  // return '';

  // let str = '?';
  // for (var key in data) {
  //   if (str != '') {
  //     str += '&';
  //   }
  //   str += key + '=' + encodeURIComponent(data[key]);
  // }
  // return str;
};

const fixedNumber = (data) => {
  if (!data) {
    return '';
  }
  const number = Number(data);
  return number.toFixed(2);
};

// const isDaya = () => {
//   // return (
//   //   CONFIG.ApplicationId === 'id.co.dayagroup.alwaysdaya.dam' ||
//   //   CONFIG.ApplicationId === 'com.daya.sobi'
//   // );
// };

// const isBrompit = () => {
//   // return CONFIG.ApplicationId === 'com.mpm.brompit';
// };

// const isWahana = () => {
//   // return CONFIG.ApplicationId === 'com.mokita.wahana';
// };

// const isHso = () => {
//   // return CONFIG.ApplicationId === 'com.hso.motorku';
// };

const getError = (data) => {
  if (data) {
    for (let d in data) {
      if (data[d]) {
        return { name: d, msg: data[d][0] };
      }
      return null;
    }
  }
  return null;
};

const getErrorMsg = (e: any) => {
  if (e && e.msg) {
    const errObj = Helpers.errorObj(e.msg);
    if (typeof e.msg === 'string') {
      return e.msg;
    }
    if (Array.isArray(e.msg) && e.msg.length && e.msg[0] && e.msg[0].length) {
      return String(e.msg[0]);
    }
    if (errObj && errObj.msg) {
      return errObj.msg;
    }
    return 'Silakan Coba lagi';
  }
  return 'Silakan Coba lagi';
};

const searchDatabyKey = (data: Array<any>, key: string): Array<any> => {
  if (key.length) {
    return data.filter((d: any) => {
      return d.search_name.indexOf(key.toLowerCase()) > -1;
    });
  }
  return data;
};

const selectSearchData = (
  data: {
    all: any;
    sale: any;
    services: any;
    part: any;
  },
  tabData: {
    id: number;
    dataName: string;
  } | null,
  originLocation: any
) => {
  if (tabData && tabData.dataName) {
    const dataSelected = data[tabData.dataName];
    if (originLocation && originLocation.latitude && originLocation.longitude) {
      const dataWithDirection = setDistance(
        dataSelected,
        originLocation.latitude,
        originLocation.longitude
      );
      return _.uniqBy(dataWithDirection, 'id');
      // return dataWithDirection;
    }

    return _.uniqBy(dataSelected, 'id');
    // return dataSelected;
  }

  return [];
};
const selectAhassData = (
  data: {
    all: any;
    sale: any;
    services: any;
    part: any;
  },
  tabData: {
    id: number;
    dataName: string;
  } | null,
  searchKey: string,
  originLocation: any
) => {
  if (tabData && tabData.dataName) {
    const dataSelected = selectDataByTab(data, tabData);
    if (originLocation && originLocation.latitude && originLocation.longitude) {
      const dataWithDirection = setDistance(
        dataSelected,
        originLocation.latitude,
        originLocation.longitude
      );
      const selectedResult = searchDatabyKey(dataWithDirection, searchKey);
      return _.uniqBy(selectedResult, 'id');
    }
    const selectedResult = searchDatabyKey(dataSelected, searchKey);
    return _.uniqBy(selectedResult, 'id');
    // return searchDatabyKey(dataSelected, searchKey);
  }

  return _.uniqBy(searchDatabyKey(data.all, searchKey), 'id');
};

const selectDataByTab = (data, tab) => {
  if (data && tab && tab.dataName) {
    return data[tab.dataName];
  }
  return [];
};

const createMarkerLists = (dataLists, ahassDetailData, tabData, mapCenter) => {
  const selected = selectDataByTab(dataLists, tabData);
  const selectedValidate = validateMarker(selected);
  const dataWithDistance = setDistance(
    selectedValidate,
    mapCenter.latitude,
    mapCenter.longitude
  );
  const dataLimit = limitArray(dataWithDistance, 12);

  const extraDetail = [...dataLimit, ahassDetailData];
  return _.uniqBy(extraDetail, 'id');
};

const validateMarker = (data) => {
  return data.filter((d) => {
    return !isNaN(parseFloat(d.latitude)) && !isNaN(parseFloat(d.longitude));
    // if () {
    //   return d;
    // }
  });
};

const dateWithFormat = (
  date = new Date()
): {
  yyyy: number;
} => {
  // iTodos
  // yy – two-digit year, e.g. 06
  // yyyy – four-digit year, e.g. 2006
  // m – one-digit month for months below 10, e.g. 4
  // mm – two-digit month, e.g. 04
  // mmm – three-letter abbreviation for month, e.g. Apr
  // mmmm – month spelled out in full, e.g. April
  // d – one-digit day of the month for days below 10, e.g. 2
  // dd – two-digit day of the month, e.g. 02
  // ddd – three-letter abbreviation for day of the week, e.g. Tue
  // dddd – day of the week spelled out in full, e.g. Tuesday

  return {
    yyyy: date.getFullYear(),
  };
};

function updateArray(arr: Array<any>, newObj: any) {
  //find the index of object from array that you want to update
  const objIndex = arr.findIndex((obj) => obj.id === newObj.id);

  // make new object of updated object.
  const updatedObj = { ...arr[objIndex], ...newObj };

  // console.log('global.js : updateArray() : original data  -> ', arr);

  // make final new array of objects by combining updated object.
  const newArr = [
    ...arr.slice(0, objIndex),
    updatedObj,
    ...arr.slice(objIndex + 1),
  ];
  // console.log('global.js : updateArray() : updated data -> ', newArr);
  return newArr;
}

const insertArray = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

const getAppStorePath = (dir) => {
  if (dir) {
    const arr = dir.split('/');
    arr.pop();
    return arr.join('/');
  }
  return null;
};

const getPathDirByMatchName = (dirs: any, s: string) => {
  if (dirs && _.isArray(dirs) && dirs.length) {
    return dirs.find((element: any) => {
      if (element && element.path) {
        return element.path.indexOf(s) > -1;
      }
      // return /shared_prefs/.test(element.path);
    });
  }
  return null;
};

const getNameFromStringTag = (str) => {
  if (str) {
    return str.replace(/(<string(\s)name=\")|(\"\>(.)+<\/string\>)/g, '');
  }
  return null;
};

const getValueFromStringTag = (str) => {
  if (str) {
    return str.replace(/(<string(\s)name=\"(.)+"\>)|(\<\/string\>)/g, '');
  }
  return null;
};

const arrStringToJson = (arr) => {
  let temp = {};
  if (arr && _.isArray(arr) && arr.length) {
    for (let index = 0; index < arr.length; index++) {
      const el = arr[index];
      const k = getNameFromStringTag(el);
      const v = getValueFromStringTag(el);
      if (k) {
        temp[k] = v;
      }
    }
  }
  return temp;
};

const xlmStringToJson = (xml) => {
  if (xml) {
    const xlmArr = xml.match(/<string(\s)name=\"(.)+"\>(.)+<\/string\>/g);
    return arrStringToJson(xlmArr);
  }
  return null;
};

const getFileName = (dirName) => {
  if (dirName) {
    const arr = dirName.split('/');
    return getLastArray(arr);
  }
  return null;
};

const createTimeArr = (datas: Array<any>) => {
  const chunkLength = 3;
  const timeChunk = chunkArray(datas, chunkLength);
  const last = getLastArray(timeChunk);
  const getLess = chunkLength - last.length;
  if (getLess) {
    for (let index = 0; index < getLess; index++) {
      timeChunk[timeChunk.length - 1].push({
        hide: true,
      });
    }
  }
  return timeChunk;
};

const chunkArray = (arr: Array<any>, chunkSize: number) => {
  return [].concat.apply(
    [],
    arr.map((elem, i) => {
      return i % chunkSize ? [] : [arr.slice(i, i + chunkSize)];
    })
  );
};

const getItemsListByKey = (lists: Array<any>, ids: any, key: string) => {
  const temp = [];
  if (lists && ids && lists.length && ids.length) {
    for (let j = 0; j < ids.length; j++) {
      const id = ids[j];

      for (let index = 0; index < lists.length; index++) {
        const list = lists[index];
        if (list[key] === id) {
          temp.push(list);
          break;
        }
      }
    }
  }
  return temp;
};

const getDataFormObjectByKeyValue = (
  lists: Array<any>,
  key: string,
  val: any
) => {
  if (lists && lists.length) {
    for (let index = 0; index < lists.length; index++) {
      const element = lists[index];
      if (element[key] === val) {
        return element;
      }
    }
  }
  return null;
};

const getValueByKeyInObject = (lists: Array<any>, key: string) => {
  const temp = [];
  if (lists && lists.length) {
    for (let index = 0; index < lists.length; index++) {
      const element = lists[index];
      temp.push(element[key]);
    }
  }

  return temp;
};

const createServiceSelection = (booking) => {
  const {
    categorySelected,
    category,
    categoryRecommendationId,
    categoryRecommendationData,
  } = booking;
  const temp = [];

  if (category === 'Regular') {
    for (let index = 0; index < categorySelected.length; index++) {
      const element = categorySelected[index];
      temp.push({
        ...element,
        service_group_id: categoryRecommendationId || null,
        service_group_name:
          (categoryRecommendationData && categoryRecommendationData.name) ||
          null,
        service_id: element.id || null,
        service_name: element.name || null,
      });
    }
  } else {
    for (let index = 0; index < categorySelected.length; index++) {
      const element = categorySelected[index];
      temp.push({
        ...element,
        service_group_id: element.service.group_id || null,
        service_id: element.id,
      });
    }
  }
  return temp;
};

const createServiceTypeId = (booking) => {
  const { categorySelected, category, categoryRecommendationId } = booking;
  if (category === 'Regular') {
    return categoryRecommendationId;
  } else {
    const temp = [];
    for (let index = 0; index < categorySelected.length; index++) {
      const element = categorySelected[index];
      temp.push(element.id);
    }
    return temp;
  }
};

const bookingFormValidation = (booking) => {
  if (!booking.ahassSelected) {
    return false;
  }
  if (!booking.schedule.time) {
    return false;
  }
  if (!booking.categorySelected) {
    return false;
  }

  return true;
};

const formatPrice = (num, cur = '') => {
  return cur + newFormatCurr(num);
};

/**
 * Usage example:
 * alert(convertToRupiah(10000000)); -> "Rp. 10.000.000"
 */

export function convertToRupiah(
  angka: number | string,
  currency: string = 'Rp ',
  defaultValue: any = null
) {
  if (!angka && defaultValue !== null) {
    return defaultValue;
  }
  return currency + newFormatCurr(angka);
  // if (angka) {
  //   var rupiah = '';
  //   var angkarev = angka
  //     .toString()
  //     .split('')
  //     .reverse()
  //     .join('');
  //   for (var i = 0; i < angkarev.length; i++) {
  //     if (i % 3 == 0) {
  //       rupiah += angkarev.substr(i, 3) + '.';
  //     }
  //   }
  //   return (
  //     currency +
  //     rupiah
  //       .split('', rupiah.length - 1)
  //       .reverse()
  //       .join('')
  //   );
  // } else {
  //   return '';
  // }
}

/**
 * Usage example:
 * alert(convertToAngka("Rp 10.000.123")); -> 10000123
 */
export function convertToAngka(rupiah: string) {
  if (rupiah) {
    return parseInt(rupiah.replace(/,.*|[^0-9]/g, ''), 10);
  } else {
    return 0;
  }
}

/**
 * Capitalize first string
 */
export function capitalizeFirstLetter(string: string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
}

const appVersioning = (config: any) => {
  return `${config.versionName}(${config.versionCode})`;
};

const onlyNumber = (val: any) => {
  if (val) {
    return val.replace(/\.|\,|\-/g, '');
  }
  return 0;
};

const getTimestamp = () => {
  return new Date().getTime();
};

const canRenderLoop = (data: any) => {
  if (data && Array.isArray(data) && data.length) {
    return true;
  }
  return false;
};

const toUpperCase = (data: any) => {
  if (typeof data === 'string') {
    return data.toUpperCase();
  }
  throw 'toUppercase only string';
};

const errorObj = (msg: any) => {
  if (_.isObject(msg)) {
    for (const key in msg) {
      if (msg.hasOwnProperty(key)) {
        const element = msg[key];
        return {
          key: key,
          msg: Array.isArray(element) && element.length ? element[0] : element,
        };
      }
    }
  }
  return null;
};
const maxNumber = (n: any, limit: any) => {
  if (n && limit) {
    const nn = Number(n);
    if (nn > limit) {
      return '9+';
    }
    return nn;
  }
  return n;
};

const checkData = (data) => {
  try {
    return !!data;
  } catch (error) {
    return 'no data';
  }
};

function changeKeyInObject(originalKey, newKey, arr) {
  const temp = [];
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    obj[newKey] = obj[originalKey];
    // delete obj[originalKey];
    temp.push(obj);
  }
  return temp;
}

const replaceObjectKey = (replacer, arr) => {
  if (!replacer && !arr) {
    throw 'data not valid';
  }
  let temp = arr;
  for (let index = 0; index < replacer.length; index++) {
    const element = replacer[index];
    const from = element['from'];
    const to = element['to'];
    // console.log('from', from);
    // console.log('to', to);
    temp = changeKeyInObject(from, to, temp);
  }
  return temp;
};

const createFilePreview = (data) => {
  if (!data) {
    return null;
  }
  if (typeof data === 'string') {
    return data;
  }
  return URL.createObjectURL(data);
};

const createFormData = (values): any => {
  const formData = new FormData();
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      const element = values[key];
      // console.log('SET', key, element);
      formData.set(key, element);
      if (typeof element === 'boolean') {
        formData.set(key, element ? '1' : '0');
      }
      if (key === 'image' && typeof element !== 'string') {
        formData.append(key, element);
      }
    }
  }
  return formData;
};

const createYearOptions = (start?: number, end?: number) => {
  const years = [];
  const currentYears = end || new Date().getFullYear();
  for (let y = currentYears; y >= (start || 2012); y--) {
    years.push({
      value: String(y),
      text: String(y),
    });
  }
  return years;
};

const enc = (data) => {
  const aes = new AESENC();
  return aes.encrypt(data, 'mokita@2020');
};

const dec = (data) => {
  const aes = new AESENC();
  return aes.decrypt(data, 'mokita@2020');
};

const isPureObject = (input) => {
  if (input === null || input === undefined) return false;
  return Object.getPrototypeOf(input).isPrototypeOf(Object);
};

const cleanValues = (vals) => {
  const temp = {};
  for (const key in vals) {
    if (vals.hasOwnProperty(key)) {
      const el = vals[key];
      if (isPureObject(el)) {
        temp[key] = el && el.value;
      } else {
        temp[key] = el;
      }
    }
  }
  return temp;
};

const discount = (p, d) => {
  return p - (p * d) / 100;
};

// const findById

const reStructureProduct = (products, gtrId) => {
  // console.log('gtrId===>');
  // console.log(gtrId);
  for (let index = 0; index < products.length; index++) {
    const el = products[index];
    const p = getDataFormObjectByKeyValue(el.pricing, 'pricing_id', gtrId);
    el['price'] = p['price'];
    el['real_price'] = discount(p['price'], el['discount_percentage']);
    el['discount'] = el['discount_percentage'];
  }
  return products;
};

const getGtr = (provice) => {
  const gtr1 = [
    'banten',
    'jakarta',
    'daerah khusus ibukota jakarta',
    'dki jakarta',
    'jawa barat',
    'jawa tengah',
    'daerah istimewa yogyakarta',
    'jawa timur',
    'di yogyakarta',
    'yogyakarta',
    'lampung',
    'bali',
    'nusa tenggara barat',
  ];

  const gtr2 = [
    'nanggroe aceh darussalam',
    'aceh',
    'sumatera utara',
    'sumatera barat',
    'riau',
    'kepulauan riau',
    'jambi',
    'bengkulu',
    'sumatera selatan',
    'kepulauan bangka belitung',
  ];

  if (gtr1.includes(provice.toLowerCase())) {
    return 'gtr1';
  }
  if (gtr2.includes(provice.toLowerCase())) {
    return 'gtr2';
  }

  return 'gtr3';

  // -------------------------------------------------
  // GT R2
  // Sumatera
  // - Nanggroe Aceh Darussalam
  // - Sumatera Utara
  // - Sumatera Barat
  // - Riau
  // - Kepulauan Riau
  // - Jambi
  // - Bengkulu
  // - Sumatera Selatan
  // - Kepulauan Bangka Belitung
  // -------------------------------------------------
  // GT R3
  // Kalimantan
  // - Kalimantan Barat
  // - Kalimantan Selatan
  // - Kalimantan Tengah
  // - Kalimantan Timur
  // - Kalimantan Utara
  // Sulawesi
  // - Gorontalo
  // - Sulawesi Barat
  // - Sulawesi Selatan
  // - Sulawesi Tenggara
  // - Sulawesi Tengah
  // - Sulawesi Utara
};
const getGtrId = (gtr) => {
  if (gtr === 'gtr1') {
    return 1;
  }
  if (gtr === 'gtr2') {
    return 2;
  }
  return 3;
};

const getProductPrice = (products) => {
  if (!products) {
    return 0;
  }
  const result = products.filter((d) => !!d.count);
  const price = result.reduce(
    (accumulator, item) => accumulator + item.real_price * item.count,
    0
  );
  return price;
};
const getDefaultProductRecomendationPrice = (products) => {
  if (!products) {
    return 0;
  }
  const result = products.filter((d) => !!d.real_price);
  const price = result.reduce(
    (accumulator, item) => accumulator + item.real_price,
    0
  );
  return price;
};

const createRecomendationProduct = (product) => {
  if (!product) {
    return null;
  }
  const product_name = [];

  return [{ ...product[0] }];

  // for (let index = 0; index < product.length; index++) {
  //   const el = product[index];
  //   if (!!el.product_status) {
  //     product_name.push(el.product_name);
  //   }
  //   // product_name.push(el.product_name);
  //   // product_name.push(el.product_name);
  //   // product_name.push(el.product_name);
  // }

  // return [
  //   {
  //     // discount_percentage
  //     is_featured: 1,
  //     id: 1,
  //     product_status: 1,
  //     product_image: _.get(product, '[0].product_image'),
  //     product_name: product_name.join(' + '),
  //     // price: getDefaultProductRecomendationPrice(product),
  //     price: _.get(product, '[0].product_image'),
  //     // real_price: getDefaultProductRecomendationPrice(product),
  //     discount: _.get(product, '[0].discount_percentage'),
  //   },
  // ];
};

const getProductSelected = (products, productsRecomendation, price_id) => {
  const cleanProducts = products.filter((d) => !!d.count);
  const cleanProductsRecomendation = productsRecomendation.filter(
    (d) => !!d.count
  );

  const tempObj = {};
  const temp = [...cleanProducts, ...cleanProductsRecomendation];
  temp.forEach((el) => {
    if (tempObj.hasOwnProperty(String(el.id))) {
      console.log('HAVE');
      tempObj[String(el.id)]['quantity'] =
        tempObj[String(el.id)]['quantity'] + el.count;
    } else {
      tempObj[String(el.id)] = {
        ...el,
        price_id: price_id,
        quantity: el.count,
      };
    }
  });

  return Object.keys(tempObj).map(function (key) {
    return tempObj[key];
  });
};

const Helpers = {
  getProductSelected,
  createRecomendationProduct,
  getProductPrice,
  reStructureProduct,
  getGtr,
  getGtrId,
  discount,
  cleanValues,
  enc,
  dec,
  createYearOptions,
  createFormData,
  createFilePreview,
  replaceObjectKey,
  checkData,
  maxNumber,
  errorObj,
  toUpperCase,
  canRenderLoop,
  getTimestamp,
  onlyNumber,
  appVersioning,
  bookingFormValidation,
  createServiceSelection,
  createServiceTypeId,
  displayMinMaxPrice,
  getItemsListByKey,
  getValueByKeyInObject,
  getDataFormObjectByKeyValue,
  createTimeArr,
  chunkArray,
  getFileName,
  dateWithFormat,
  validateMarker,
  xlmStringToJson,
  getPathDirByMatchName,
  getAppStorePath,
  getError,
  getErrorMsg,
  // isDaya,
  // isBrompit,
  // isHso,
  // isWahana,
  fixedNumber,
  objectToParams,
  isCanParseJson,
  parseToJson,
  isCanStringifyJson,
  typeOf,
  paramsToObject,
  getParamsFromLinking,
  randomString,
  addSerchKey,
  formatPrice,
  motorListData,
  fibonacciNumber,
  getUserNameByEmail,
  setRoundThenFixed,
  setMeterToKm,
  printDirectionInKm,
  setDistance,
  setMarkerDistance,
  formatDate,
  checkValue,
  formatCurrency,
  compareProfile,
  getLastArray,
  isEmpty,
  getKeyByRandom,
  getKeybyDate,
  milesToKm,
  sizeToScreenRatio,
  printDate,
  printNewsDate,
  getDay,
  isLoading,
  getPercentByLength,
  isDateAvaliable,
  dateValue,
  getObjectByValueName,
  validateMaxPoint,
  cleanSpecialChar,
  addVehicleSpace,
  haveSpace,
  removeSpace,
  removeDot,
  printMileage,
  // formatVehicle,
  getYearLists,
  getIconNameByLevel,
  objectToArray,
  // openCall,
  trimText,
  isUrl,
  debugGroup,
  middlePoint,
  getDistanceFromLatLonInKm,
  limitArray,
  getIdFromArrayObject,
  removeArrayObjectById,
  removeArrayObjectByKeyValue,
  filterLastVisit,
  filterAhassDealer,
  filterAhassList,
  createFakeDistance,
  getGoogleAddress,
  filterDataById,
  addIdCity,
  getDatabyIndex,
  searchDatabyKey,
  selectAhassData,
  selectSearchData,
  createMarkerLists,
  updateArray,
  insertArray,
  convertToAngka,
  convertToRupiah,
  capitalizeFirstLetter,
};

export default Helpers;
