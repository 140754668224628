import { store } from '../../store';
import { TYPE } from './index';

export const requestStart = (reqInfo, reqName) => {
  store.dispatch({
    type: TYPE.REQ_START,
    reqName,
    reqInfo,
  });
};

export const requestSuccess = (res, reqName) => {
  store.dispatch({
    type: TYPE.REQ_SUCCESS,
    data: res,
    reqName,
  });
};

export const requestFail = (error, reqName) => {
  store.dispatch({
    type: TYPE.REQ_FAIL,
    data: error,
    reqName,
  });
};

export const requestReset = () => {
  store.dispatch({
    type: TYPE.REQ_RESET,
  });
};

export default {
  requestStart,
  requestSuccess,
  requestFail,
  requestReset,
};
