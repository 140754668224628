// @ts-nocheck

import React from 'react';
// import { Motion, spring } from 'react-motion';
import { motion } from 'framer-motion';

const variants = {
  active: {
    opacity: 1,
    // visibility: 'visible',
    top: 0,
  },
  inActive: {
    opacity: 0,
    // visibility: 'visible',
    top: '20px',
  },
};

const Fade = ({ isOn, children, ...props }) => (
  <motion.div
    animate={isOn ? 'active' : 'inActive'}
    variants={variants}
    {...props}>
    {children}
  </motion.div>
);

class Modal extends React.Component {
  state = {
    visible: false,
    isSetDelay: false,
  };

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isSetDelay: true });
    }, 400);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.setState({ isSetDelay: false });
    }
  }

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  modalClass = (visible, isSetDelay) => {
    if (visible && isSetDelay) {
      return 'bf-modal is-show is-delay';
    }
    if (visible) {
      return 'bf-modal is-show';
    }
    return 'bf-modal';
  };

  render() {
    const { onClose, visible, children } = this.props;
    const { isSetDelay } = this.state;
    // console.log('visible', visible);
    return (
      <Fade isOn={visible} className={this.modalClass(visible, isSetDelay)}>
        {/* <div className={this.modalClass(visible, isSetDelay)}> */}
        <div className="bf-modal__block" onClick={onClose} />
        <div className="bf-modal__container">
          <div className="bf-modal__header clearfix">
            <span
              onClick={onClose}
              className="icon close f-right c-pointer"></span>
          </div>
          <div className="bf-modal__content">{children}</div>
        </div>
        {/* </div> */}
      </Fade>
    );
  }
}

export default Modal;
