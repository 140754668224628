import { store } from '../../store';
import { TYPE } from './index';
import { STORAGE } from 'utils';

const set = async (keyValue: any) => {
  store.dispatch({
    type: TYPE.SET_DINAMIC,
    value: keyValue,
  });
  return 1;
};
const reset = async () => {
  store.dispatch({
    type: TYPE.RESET,
  });
  return 1;
};

const setProfile = async (data: any) => {
  const setToStorage = await STORAGE.LOCAL.set('profile', data, true);
  const setToStore = await set({
    profile: data,
  });
  return {
    setToStorage,
    setToStore,
  };
};

const getProfile = async () => {
  const profile = STORAGE.LOCAL.get('profile', true);
  if (profile) {
    const setToStore = await set({
      profile: profile,
    });
    return profile;
  }
  return null;
};

const setToken = async (token: any, withStorage?: boolean) => {
  if (withStorage) {
    const setToStorage = STORAGE.SESSION.set('token', token, true);
  }
  const setToStore = await set({
    token: token,
  });
  return {
    setToStore,
  };
};

const init = async () => {
  const token = STORAGE.SESSION.get('token', true);
  const profile = STORAGE.LOCAL.get('profile', true);
  const data = {
    profile: profile,
    token: token,
    isLogin: !!token,
  };
  const setInitial = await set(data);
  if (setInitial) {
    return data;
  }
  return null;
};

const logOut = async () => {
  const removeStorage = STORAGE.LOCAL.removeAll();
  const removeSession = STORAGE.SESSION.removeAll();
  return await set({
    profile: null,
    token: null,
    isLogin: 0,
  });
};

export default {
  set,
  setToken,
  getProfile,
  setProfile,
  init,
  logOut,
  reset,
};
