import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import fetchReducer from './fetchReducer';
import loadingReducer from './loadingReducer';
import userflowReducer from './userflowReducer';
import modalReducer from './modalReducer';
import sidebarReducer from './sidebarReducer';
import popupReducer from './popupReducer';

const rootReducers = combineReducers({
  fetch: fetchReducer,
  loading: loadingReducer,
  userFlow: userflowReducer,
  modal: modalReducer,
  sidebar: sidebarReducer,
  popup: popupReducer,
});

const persistConfig = {
  key: 'bf',
  storage,
  whitelist: ['userFlow'],
};

export default persistReducer(persistConfig, rootReducers);
// export default rootReducers;
