import { TYPE } from './index';
import { store } from '../../store';

const show = (data) => {
  store.dispatch({
    type: TYPE.SHOW_POPUP,
    payload: data,
  });
};

const hide = () => {
  store.dispatch({
    type: TYPE.HIDE_POPUP,
  });
};

const popupAction = {
  show,
  hide,
};

export default popupAction;
