// @ts-nocheck

import React from 'react';
import { Helpers } from 'utils';
import { motion } from 'framer-motion';
import CONFIG from 'config';

const variants = {
  active: {
    height: 'auto',
  },
  inActive: {
    height: 48,
  },
};

const Box = ({ isOn, children, ...props }) => (
  <motion.div
    animate={isOn ? 'active' : 'inActive'}
    variants={variants}
    {...props}>
    {children}
  </motion.div>
);

class Card extends React.Component {
  state = {
    showMore: false,
  };

  handleToggle = () => {
    this.setState({
      showMore: !this.state.showMore,
    });
  };

  renderProductName = (p, s) => {
    const { more, data } = p;
    const { product_name } = data;
    const { showMore } = s;

    const t = showMore ? 'SEMBUNYIKAN' : 'LIHAT LEBIH';

    if (!!more) {
      return (
        <>
          <Box className="o-hidden" isOn={showMore}>
            <p className={'d-ib-cs bf-card__text'}>{product_name}</p>
          </Box>
          <span
            onClick={this.handleToggle}
            className="d-block c-orange fw-medium fz-14 c-pointer">
            {t} <span className="icon-arrow d-ib"></span>{' '}
          </span>
        </>
      );
    }
    return <p className={'d-ib-cs bf-card__text'}>{product_name}</p>;
  };

  render() {
    const { data, onMin, onPlus } = this.props;
    const {
      product_name,
      title,
      isBestSeller,
      description,
      price,
      discount,
      count,
      is_featured,
      product_image,
      product_status,
    } = data;

    return (
      <div className={!!product_status ? 'bf-card' : 'bf-card bf-card-disable'}>
        {!!is_featured && <div className="bf-card__ribbon">Best Seller</div>}

        <div className="bf-card__content">
          <img
            className="bf-card__img d-ib-cs"
            src={
              !!product_image
                ? CONFIG.PRODUCT_URL + product_image
                : require('../../assets/images/dummy/main.png')
            }
            alt={product_name}
          />
          <div className="bf-card__content-inner">
            {this.renderProductName(this.props, this.state)}
          </div>
        </div>
        <div className="bf-card__footer clearfix">
          <div className="bf-card__price">
            {!!discount && (
              <div>
                <span className="td-th fw-book mr-6 d-ib">
                  {Helpers.formatPrice(price, 'Rp ')}
                </span>
                <span className="fw-black c-red fw-black d-ib">
                  {discount}% off
                </span>
              </div>
            )}
            <div>
              <span className="fw-black">
                {!!discount
                  ? Helpers.formatPrice(
                      Helpers.discount(price, discount),
                      'Rp '
                    )
                  : Helpers.formatPrice(price, 'Rp ')}
              </span>
            </div>
          </div>
          <div className="bf-card__count">
            <button
              onClick={() => {
                if (!!product_status) {
                  onMin(data);
                }
                // onMin(data);
              }}
              type="button"
              className="count-button d-ib">
              -
            </button>
            <input
              disabled
              className="count-input d-ib"
              value={count || '0'}
              type="text"
            />
            <button
              onClick={() => {
                if (!!product_status) {
                  // onMin(data);
                  onPlus(data);
                }
                // onPlus(data);
              }}
              type="button"
              className="count-button d-ib">
              +
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
