import React from 'react';
import { Helpers } from 'utils';


export default class BaseContainer<P> extends React.Component<P> {
  internalProp: P;

  componentDidMount() { }

  componentDidUpdate(prevProps, prevState) { }

  setDocumentTitle = (props: any) => {
    if (typeof props === "string") {
      return document.title = props;
    }
    const { title } = props.pageProps;
    return document.title = title || "No title";
  }

  getIdFromParams = (match) => {
    return match && match.params && match.params.id || null
  }

  createGetParamsAsUrl = (pagination, params) => {
    const par = Helpers.objectToParams(params);
    return `/${pagination.get}/${pagination.skip}${par}`
  }

  navigateToEdit = (props: any, id: number | string) => {
    const { location, history } = props;
    // console.log(history, location.pathname + "/edit/" + id);
    return this.navigate(history, location.pathname + "/edit/" + id);
  }

  navigateToAdd = (props: any) => {
    const { location, history } = props;
    // console.log(history, location.pathname + "/edit/" + id);
    return this.navigate(history, location.pathname + "/add/");
  }
  navigateToDetail = (props: any, id: number | string) => {
    const { location, history } = props;
    return this.navigate(history, location.pathname + "/detail/" + id);
  }

  // THIS FOR NAVIGATE
  navigate = (history: any, to: string) => {
    history.push(to);
  }

  // THIS FOR GOBACK
  goBack = (history: any) => {
    history.goBack();
  }
  render() {
    return null
  }
}
