// @ts-nocheck

import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './scss/style.scss';
import { GlobalLoading, GlobalModal, GlobalPopup } from 'containers';

import { STORAGE } from 'utils';
import { userAction } from 'redux-store';
import { FETCH, PATH } from 'fetch';

import { connect } from 'react-redux';

import { AnimatePresence } from "framer-motion";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers

// Pages
// const Login = React.lazy(() => import('./containers/Login'));
const Landing = React.lazy(() => import('./containers/Landing'));
const FormUser = React.lazy(() => import('./containers/FormUser'));
const FormLocation = React.lazy(() => import('./containers/FormLocation'));
const FormMaps = React.lazy(() => import('./containers/FormMaps'));
const Product = React.lazy(() => import('./containers/Product'));
const Confirmation = React.lazy(() => import('./containers/Confirmation'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class LL extends React.Component {

  componentDidMount() {
    const { history } = this.props;
    history.replace('reseller');
  }

  render() {
    return null
  }

}

class App extends Component {
  state = {}
  async componentDidMount() {
  }

  setUser = async () => {

  }

  render() {
    const { state } = this;
    const { ready } = state;

    return (
      <>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <AnimatePresence>
              <Switch>
                <Route exact path="/" name="Landing Page" render={props => <LL {...state} {...props} />} />
                <Route exact path="/reseller" name="Landing Page" render={props => <Landing {...state} {...props} />} />
                <Route exact path="/form-user" name="Form User Page" render={props => <FormUser {...state} {...props} />} />
                <Route exact path="/form-location" name="Form Location Page" render={props => <FormLocation {...state} {...props} />} />
                <Route exact path="/form-maps" name="Maps Page" render={props => <FormMaps {...state} {...props} />} />
                <Route exact path="/product" name="Product Page" render={props => <Product {...state} {...props} />} />
                <Route exact path="/confirmation" name="Confirmation Page" render={props => <Confirmation {...state} {...props} />} />
                {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} /> */}
                {/* <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} /> */}
                {/* <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} /> */}
                {/* <Route path="/" name="Home" render={props => <TheLayout {...state} {...props} />} /> */}

                {/* <PrivateRoute path="/" name="Home" {...state} /> */}
              </Switch>
            </AnimatePresence>

          </React.Suspense>
        </HashRouter>
        <GlobalLoading />
        {/* <GlobalModal /> */}
        <GlobalPopup />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state,
});

export default connect(mapStateToProps, null)(App);
