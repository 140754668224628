export const TYPE = {
  SHOW_MODAL: 'GLOBAL/SHOW_MODAL',
  HIDE_MODAL: 'GLOBAL/HIDE_MODAL',
};

const DEFAULVISIBLE = true;

const INITIALSTATE = {
  visible: DEFAULVISIBLE,
  options: {},
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case TYPE.SHOW_MODAL: {
      return {
        ...state,
        visible: true,
        options: action.payload ? { ...action.payload } : { ...state.options },
      };
    }
    case TYPE.HIDE_MODAL: {
      return {
        ...state,
        visible: false,
      };
    }
    default:
      return state;
  }
}
