// @ts-nocheck

import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modalx from '../../components/Modalx';
// import modalxAction from '../../config/redux/reducer/modalxReducer/actions';
import modalxAction from '../../redux-store/reducers/modalReducer/actions';

class GlobalModal extends React.Component {
  close() {
    modalxAction.hide();
  }

  render() {
    const { modal } = this.props;
    const { options } = modal;

    return (
      <Modalx
        type={options && options.type}
        visible={modal.visible}
        closeAction={options && options.closeAction}
        disableClose={options && options.disableClose}
        wrapperClass={options && options.wrapperClass}
        headerClass={options && options.headerClass}
        footerClass={options && options.footerClass}
        contentClass={options && options.contentClass}
        {...options}
      >
        {options && options.children}
      </Modalx>
    );
  }
}

const mapStateToProps = state => ({
  modal: state.modal
});

export default connect(mapStateToProps)(GlobalModal);
