import React from 'react';
// import popupAction from '../../config/redux/reducer/popupReducer/actions';
import popupAction from '../../redux-store/reducers/popupReducer/actions';
// import Button from '../../components/Button';
import _ from 'lodash';

const Button = (p) => {
  return (
    <button className="round-button" {...p}>
      {p.children}
    </button>
  );
};

class Popupx extends React.Component {
  popupTimeOut = 300;
  state = {
    popupClass: '',
  };

  componentDidMount() {}

  // componentWillUpdate(nextProps, nextState) {
  //   const { visible } = nextProps;

  //   if (visible !== this.props.visible) {
  //     this.handleVisible(visible);
  //   }
  // }

  componentDidUpdate(prevProps) {
    const { visible } = prevProps;
    if (visible !== this.props.visible) {
      this.handleVisible(visible);
    }
  }

  handleVisible(visibility) {
    if (visibility) {
      this.setShow();
    } else {
      this.setHide();
    }
  }

  setShow() {
    this.setState({
      popupClass: 'is-open',
      isRender: true,
    });
    setTimeout(() => {
      this.setState({
        popupClass: this.state.popupClass + ' is-active',
      });
    }, this.popupTimeOut);
  }

  setHide() {
    this.setState({
      popupClass: 'is-open',
    });
    setTimeout(() => {
      this.setState({
        popupClass: '',
        isRender: false,
      });
      popupAction.hide();
    }, this.popupTimeOut);
  }

  renderDefaultChildren(refreshText, refreshAction) {
    return (
      <div className="popupx-children">
        <button
          onClick={(e) => {
            if (refreshAction) {
              refreshAction();
              this.setHide();
            } else {
              window.location.reload();
            }
          }}
          className="btn btn-secondary btn-small font-xxs">
          {refreshText || 'Refresh'}
        </button>
      </div>
    );
  }

  renderDefaultButton = (
    closeAction,
    closeText,
    children,
    refreshText,
    refreshAction,
    type
  ) => {
    return (
      <div className="d-flex align-items-center justify-content-sm-center mx-n2">
        <div className="px-2">
          <Button
            // size="small"
            // variant="primary"
            // transparent={type == 'reverseButton' ? true : false}
            onClick={() => {
              closeAction && closeAction();
              this.setHide();
            }}>
            <span className="font-xs">{closeText || 'Okay'}</span>
          </Button>
        </div>
        {refreshText && (
          <div className="px-2">
            <Button
              // size="small"
              // variant="primary"
              // transparent={type == 'reverseButton' ? false : true}
              onClick={() => {
                refreshAction && refreshAction();
                this.setHide();
              }}>
              <span className="font-xs">{refreshText || 'Refresh'}</span>
            </Button>
          </div>
        )}
      </div>
    );
  };

  handleImage = (image) => {
    // let defaultImage = require('../../../images/svg/ijoin-order-timeout.svg');

    if (!image) {
      // return defaultImage;
    }

    return image;
  };

  handleBody = (p = 'Error') => {
    if (_.isArray(p)) {
      return (
        <ul className="list pl-0">
          {p.map((e, i) => {
            return <li key={i.toString()}>{e}</li>;
          })}
        </ul>
      );
    }

    return p;
  };

  render() {
    const {
      children,
      closeAction,
      refreshAction,
      title,
      body,
      type,
      closeText,
      refreshText,
      image,
      disableDefaultButton,
      replaceDefaultButton,
    } = this.props;
    const { popupClass, isRender } = this.state;
    const cName = 'popupx ' + popupClass;

    if (!isRender) {
      return null;
    }

    return (
      <div className={cName}>
        <div
          className="popupx-overlay"
          onClick={() => {
            closeAction && closeAction();
            this.setHide();
          }}
        />
        <div className={`popupx-wrapper type-${type}`}>
          <div className="popupx-inner">
            <div className="popup-leftcol">
              <div className="popupx-header fw-medium gutter-bottom-mini">
                {title || 'Oups!'}
              </div>
              <div className="popupx-content gutter-bottom">
                {this.handleBody(body)}
              </div>
              {/* <div className="popupx-image d-none d-sm-block">
                <img src={this.handleImage(image)} alt="popup img" />
              </div> */}
              <div className="popupx-footer py-md-4">
                {disableDefaultButton && replaceDefaultButton}
                {!disableDefaultButton &&
                  this.renderDefaultButton(
                    closeAction,
                    closeText,
                    children,
                    refreshText,
                    refreshAction,
                    type
                  )}
              </div>
            </div>
            {/* <div className="popup-rightcol">
              <div className="popupx-image">
                <img src={this.handleImage(image)} alt="popup img" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Popupx;
