import { store } from '../../store';
import { TYPE } from './index';

const show = (data: { type: 'block' | 'bar' } = { type: 'block' }) => {
  store.dispatch({
    type: TYPE.SHOW_LOADING,
    payload: data,
  });
};

const hide = () => {
  store.dispatch({
    type: TYPE.HIDE_LOADING,
  });
};

const loadingAction = {
  show,
  hide,
};

export default loadingAction;
