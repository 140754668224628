export const TYPE = {
  SHOW_LOADING: 'GLOBAL/SHOW_LOADING',
  HIDE_LOADING: 'GLOBAL/HIDE_LOADING',
};

const DEFAULT_VISIBLE = false;

const INITIALSTATE = {
  visible: DEFAULT_VISIBLE,
  options: {
    type: 'bar',
  },
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case TYPE.SHOW_LOADING: {
      return {
        ...state,
        visible: true,
        options: action.payload ? { ...action.payload } : { ...state.options },
      };
    }
    case TYPE.HIDE_LOADING: {
      return {
        ...state,
        visible: DEFAULT_VISIBLE,
      };
    }
    default:
      return state;
  }
}
