// @ts-nocheck

import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Modalx from '../../components/Modalx';
// import modalxAction from '../../config/redux/reducer/modalxReducer/actions';
import popupAction from '../../redux-store/reducers/popupReducer/actions';


// import React from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import LoadingBar from '../../components/LoadingBar';
// import popupAction from '../../config/redux/reducer/popupReducer/actions';
import Popupx from '../../components/Popup';

// import Popupx from .

class GlobalMessage extends React.Component {
  close() {
    popupAction.hide();
  }

  render() {
    const { popupX } = this.props;
    const { options, visible } = popupX;

    return (
      <Popupx popupX={popupX} visible={visible} {...options}>
        {options && options.children}
      </Popupx>
    );
  }
}

const mapStateToProps = state => ({
  popupX: state.popup
});

export default connect(mapStateToProps)(GlobalMessage);
