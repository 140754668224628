export const TYPE = {
  SET_DINAMIC: 'SET_USER_FLOW',
  RESET: 'RESET_USER_FLOW',
};

const initialState = {
  profile: null,
  isLogin: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case TYPE.SET_DINAMIC: {
      return {
        ...state,
        ...action.value,
      };
    }
    case TYPE.RESET: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
