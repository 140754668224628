export const TYPE = {
  REQ_START: 'req/START',
  REQ_SUCCESS: 'req/SUCCESS',
  REQ_FAIL: 'req/FAIL',
  REQ_RESET: 'req/RESET'
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case TYPE.REQ_START:
      return {
        ...state,
        [action.reqName]: {
          ...state[action.reqName],
          reqInfo: action.reqInfo,
          isLoading: true
        }
      };
    case TYPE.REQ_SUCCESS:
      return {
        ...state,
        [action.reqName]: {
          ...state[action.reqName],
          response: action.data,
          isError: false,
          isLoading: false
        }
      };
    case TYPE.REQ_FAIL:
      return {
        ...state,
        [action.reqName]: {
          ...state[action.reqName],
          error: action.data,
          isError: true,
          isLoading: false
        }
      };
    case TYPE.REQ_RESET:
      return {
        reset: true
      };
    default:
      return state;
  }
}
