const DEV = {
  name: 'DEV', // Use when you need to test something
  showConsole: {
    // show console on http request
    showUrl: true, // show url
    showPostBody: false, // show post body
    showResponse: false, // show response
  },
  forceLogin: true, // Auto login base on token
  token: 'ken',
  createLog: false,
  initialRoute: 'Dashboard', // Default route when start dev, see on routeList.ts
};

export default DEV;
