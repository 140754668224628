import FN from './helpers';

const storageType = {
  local: localStorage,
  session: sessionStorage,
};

const createData = (data: any, isEncrypt: boolean) => {
  if (isEncrypt) {
    if (typeof data === 'string') {
      return FN.enc(data);
    } else {
      return FN.enc(JSON.stringify(data));
    }
  } else {
    if (typeof data === 'string') {
      return data;
    } else {
      return JSON.stringify(data);
    }
  }
};

const set = (type: 'session' | 'local') => (
  key: string,
  value: any,
  encrypt: boolean = false
) => {
  const data = createData(value, encrypt);
  const storage = storageType[type];
  return storage.setItem(key, data);
};

const get = (type: 'session' | 'local') => (
  key: string,
  encrypt: boolean = false
) => {
  const storage = storageType[type];
  var data = storage.getItem(key);

  if (data) {
    if (encrypt) {
      return FN.parseToJson(FN.dec(data));
    } else {
      return FN.parseToJson(data);
    }
  }
  return data;
};

const remove = (type: 'session' | 'local') => (key: string) => {
  const storage = storageType[type];
  return storage.removeItem(key);
};

const removeAll = (type: 'session' | 'local') => () => {
  const storage = storageType[type];
  return storage.clear();
};

const LOCAL = {
  set: set('local'),
  get: get('local'),
  remove: remove('local'),
  removeAll: removeAll('local'),
};

const SESSION = {
  set: set('session'),
  get: get('session'),
  remove: remove('session'),
  removeAll: removeAll('session'),
};

export default {
  LOCAL,
  SESSION,
};
