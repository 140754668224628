import React from 'react';
// import modalxAction from '../../config/redux/reducer/modalxReducer/actions';
import modalxAction from '../../redux-store/reducers/modalReducer/actions';

class Modalx extends React.Component {
  modalTimeOut = 200;
  state = {
    modalClass: '',
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { visible } = prevProps;

    if (visible !== this.props.visible) {
      this.handleVisible(this.props.visible);
    }
  }

  handleVisible(visibility) {
    if (visibility) {
      this.setShow();
    } else {
      this.setHide();
    }
  }

  setShow() {
    this.setState({
      modalClass: 'is-open',
      isRender: true,
    });
    setTimeout(() => {
      this.setState({
        modalClass: this.state.modalClass + ' is-active',
      });
    }, this.modalTimeOut);
  }

  setHide() {
    this.setState({
      modalClass: 'is-open',
    });
    setTimeout(() => {
      this.setState({
        modalClass: '',
        isRender: false,
      });
    }, this.modalTimeOut);
  }

  /**
   * @description Create a handler if props are func or a string
   */
  renderProps = (h) => {
    if (!h) return false;

    if (typeof h === 'function') {
      return h();
    }

    return h;
  };

  renderCloseButton = () => {
    const { disableClose, closeAction } = this.props;
    return (
      <div className="closebtn-wrapper">
        <span
          className="icon icon-sa-close is-pointer"
          onClick={() => {
            closeAction && closeAction();
            modalxAction.hide();
          }}
        />
      </div>
    );
  };

  render() {
    const {
      children,
      closeAction,
      header,
      footer,
      type,
      disableClose,
      wrapperClass,
      contentClass,
      headerClass,
      footerClass,
    } = this.props;
    const { modalClass, isRender } = this.state;
    const cName = 'modalx is-active is-open' + modalClass;

    if (!isRender) {
      // return null;
    }

    return (
      <div className={cName}>
        <div
          className="modalx-overlay"
          onClick={() => {
            closeAction && closeAction();
            !disableClose && modalxAction.hide();
          }}
        />
        <div
          className={`modalx-wrapper ${type} ${
            wrapperClass ? wrapperClass : ''
          }`}>
          <div className={`modalx-header ${headerClass ? headerClass : ''}`}>
            {this.renderProps(header)}
            {this.renderCloseButton()}
          </div>
          <div className={`modalx-content ${contentClass ? contentClass : ''}`}>
            {children}
          </div>
          <div className={`modalx-footer ${footerClass ? footerClass : ''}`}>
            {this.renderProps(footer)}
          </div>
        </div>
      </div>
    );
  }
}

export default Modalx;
