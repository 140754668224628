import React from 'react';

const LoadingBar = (props) => {
  return (
    <div className="loading-bar">
      <div className="loading-progress" />
    </div>
  );
};

export default React.memo(LoadingBar);
